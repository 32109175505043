import React from 'react';
import ReactDOM from 'react-dom/client';
import './Template/index.css';
import reportWebVitals from './Util/reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'fontsource-noto-sans-jp';
import { Container } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { TopBar } from './Template/TopBar';
import { BottomBar } from './Template/BottomBar';
// fontFamilyを上書き
export const theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <TopBar />
        <Container maxWidth="lg" sx={{ marginTop: '4rem' }}>
          <Router />
        </Container>
        <BottomBar />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
