import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../../Template/Title';
export function TOS() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <TitleDynamic title="利用規約" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '4rem', fontWeight: '600' }}
          >
            利用規約
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant="body2">
            本利用規約は、BuildFanbase!（以下、運営会社といいます）が提供する「BuildFanBase!」（以下、「本サービス」といいます）において、
            運営会社と本サービスを利用する利用者（以下、「利用者」といいます。）の間で合意されるものです。
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第1条 (定義)
          </Typography>
          <Typography variant="body2">
            本規約では、次の用語を使用します。
            <ol>
              <li>
                「クリエイター」とは、本サービスを通じて、サービスを出品する利用者のことをいいます。
              </li>
              <li>
                「クライアント」とは、本サービスを通じて、クリエイターからサービスを購入した利用者のことをいいます。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第2条 (適用)
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                本規約は、利用者と運営会社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
              </li>
              <li>
                運営会社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
              </li>
              <li>
                本規約の定めが前項の個別規定の定めと矛盾する場合には、個別規定において特段の定めなき限り、個別規定の定めが優先されるものとします。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第3条 (利用登録)
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                本サービスにおいては、利用希望者が本規約に同意の上、運営会社の定める方法によって利用登録を申請し、運営会社がこれに対してアカウントを利用希望者に発行することによって、利用登録が完了するものとします。
              </li>
              <li>
                運営会社は、利用登録の申請内容に以下の事由があると判断した場合、アカウントを発行しない、または発行したアカウントを削除するなどの措置を行うことがあり、その理由については一切の開示義務を負わないものとします。
                運営会社は本項に定めた措置により、利用者に生じた損害から一切免責されるものとします。
              </li>
              <ol>
                <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                <li>本規約に違反したことがある者からの申請である場合</li>
                <li>その他、運営会社が利用登録を相当でないと判断した場合</li>
              </ol>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第3条（アカウント管理）
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                利用者は、本サービスの利用に際して利用者自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。
              </li>
              <li>
                利用者は、いかなる場合にも、アカウントを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。
              </li>
              <li>
                利用者は、自己の責任において、利用者のアカウントが不正に利用されないよう厳重に管理しなければなりません。
                運営会社は、アカウントを利用して行われた一切の行為をそのアカウントを受理した利用者自身による利用とみなすことができます。
              </li>
              <li>
                アカウントが第三者に使用されたことによって生じた損害は、運営会社に故意又は重大な過失がある場合を除き、運営会社は一切の責任を負わないものとします。
              </li>
              <li>
                運営会社は、利用者が本規約に違反または違反するおそれがあると認めた場合、あらかじめ利用者に通知することなく、アカウントを削除または変更等の措置を行うことができるものとします。
                運営会社は、これらの措置を講じた理由を開示する義務を負うものではなく、また、回答いたしません。
              </li>
              <li>
                運営会社は、最終のログインから1年以上経過している利用者のアカウントについて、
                アカウントの停止または削除の実施日1ヵ月前までに当該利用者の登録メールアドレスに事前通知することによって、削除することができます。
              </li>
              <li>
                運営会社は、売上が付与されてから1年が経過した場合、紐付けされていた未振込売上を失効させることができます。
              </li>
              <li>削除されたアカウントは、紐付けされていた未振込売上が失効します。</li>
              <li>
                利用者の本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。
                利用者が誤ってアカウントを削除した場合であっても、アカウントの復旧はできません。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第4条（利用停止）
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                利用者は本サービスを利用するにあたり、本規約の定めを遵守するものとします。また運営会社は利用者の行為が本規約に違反している場合には、該当利用者に事前に通知することなく、本サービスの利用停止、取引の中止措置などを講じることができるものとします。
              </li>
              <li>
                運営会社は、前項に定める措置により利用者に生じた損害から、一切免責されるものとします。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第5条（本サービスの提供）
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                利用者は、本サービスの利用に際して必要な通信機器、通信手段、電力などを利用者の費用と責任で用意しなければなりません。
              </li>
              <li>
                運営会社は、利用者の登録情報、本人確認の有無、その他運営会社が必要と判断する条件を満たした利用者に限定して、本サービスの全部、または一部を提供することができるものとします。
              </li>
              <li>
                運営会社は、あらかじめ利用者に通知することなく、いつでも、本サービスの全部、または一部の内容を変更、または提供の中止することができるものとします。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第6条（運営会社との売買契約）
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                本サービスにおいては，運営会社が提供するサービスについて、利用者が運営会社に対して購入の申し込みをし，
                これに対して運営会社が当該申し込みを承諾した旨の通知をすることによって売買契約が成立するものとします。
              </li>
              <li>
                当社は，利用者が以下のいずれかの事由に該当する場合には，当該利用者に事前に通知することなく，前項の売買契約を解除することができるものとします。
                <ol>
                  <li>利用者が本規約に違反した場合</li>
                  <li>理由を問わず、利用者のアカウントが削除、制限された場合</li>
                  <li>その他当社とユーザーの信頼関係が損なわれたと認める場合</li>
                </ol>
              </li>
              <li>
                運営会社が提供するサービスに関する決済方法，購入の申し込みのキャンセル方法，または返品方法等については，別途当社が定める方法によります。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第7条（利用者間契約）
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                本サービスでは、クリエイターが出品するサービスに対しクライアントが購入申請を運営会社所定の方法でを行い、
                クリエイターが運営会社所定の方法により当該購入申請を承認することでクライアントとクリエイター間において、
                次項に定める利用者間契約が成立します。
                なお、利用者間契約はクライアントとクリエイターが直接契約を締結するものであり、
                運営会社は利用者間契約の当事者とはならず、クライアントとクリエイターのいずれの立場に関する責任も負いません。
              </li>
              <li>
                利用者間契約は、次の各号に定める内容を条件とした契約とします。ただし、利用者間契約を結ぶうえでクライアントとクリエイターが同意した特約（以下、「特約」といいます。）と矛盾する場合は、特段の定めがない限り、特約の定めが優先されるものとします。
                <ol>
                  <li>
                    クライアントは、クリエイターがサービスを提供するにあたって必要な情報やデータ（要望、参考となる画像やURL、納品のためのレギュレーション、その他いかなる内容を含みます。以下、「資料」といいます。）を全て利用者間契約の締結前にクリエイターに対して提出しなければなりません。
                    クリエイターはクライアントに対して、クライアントの個別の状況に起因する資料を除いて、サービスを提供するために必要と考えられる資料を可能な限り配慮して提出を求めるものとします。
                    利用者間契約の締結前にクライアントからクリエイターに提出された資料（以下、「契約時資料」といいます。）は利用者契約の一部に含まれます。
                    利用者間契約の締結後に不足している資料が判明した場合には、クライアントは遅滞なく該当の資料（以下、「追加資料」といいます。）をクリエイターに対して提供する必要があります。
                  </li>
                  <li>
                    クリエイターは、契約時資料と追加資料（以下、「有効資料」といいます。）に基づいたサービスを提供する義務を負います。
                  </li>
                  <li>
                    クライアントはクリエイターに対して、クリエイターによるサービスの対価として報酬の支払義務を負うものとします。
                  </li>
                  <li>
                    報酬は、クライアントとクリエイターが利用者間契約締結時に同意した金額を支払うものとします。
                    いかなる理由であっても、利用者間契約締結後に金額を変更することはできません。
                  </li>
                  <li>
                    クリエイターがサービスを提供した場合であっても、利用者間契約に特約がないかぎり、
                    サービスに含まれる著作物の著作権、知的財産権、その他一切の権利（以下、「サービスに関する権利」といいます。）はクライアントに譲渡されず、クリエイターに帰属するものとします。
                    利用者間契約締結後、権利の譲渡を行うには、クリエイターとクライアントの双方の同意が必要です。
                    ただし、利用者間契約に特約があったとしても、クリエイターがサービスの提供の義務を果たすまでサービスに関する権利はクリエイターに帰属します。
                  </li>
                  <li>
                    クリエイターの提供するサービスについて、サービスの内容や完成度について一切の保証を行わない場合を「無保証での提供」といいます。
                    運営会社所定の方法で、利用者間契約にコミッション特約を指定した場合、クリエイターは、資料に従う義務はなく、無保証での提供とすることができます。
                  </li>
                  <li>
                    クリエイターはサービスを無保証での提供とする場合、クリエイターはクライアントが提出した資料に可能な限り配慮してサービス提供を行うものとします。
                  </li>
                  <li>
                    契約時資料に明らかな不足があり、有効資料に基づいてサービスを提供することが困難になったとクリエイターが判断した場合、
                    クリエイターは契約時資料に基づいてサービスを提供するか、契約時資料と追加資料の双方に基づいたサービスを無保証での提供とすることができるものとします。
                    ただし、クライアントはクリエイターが提供困難であると指定した追加資料を有効資料から撤回した場合はこの限りではありません。
                  </li>
                  <li>
                    クリエイターは運営会社所定の方法でサービスを納品（以下、「納品」といいます。）することができます。
                    クライアントは、無保証での提供である場合を除き、クリエイターが納品したサービスに対して、納品確認を行うことができるものとします。
                    クライアントが運営会社所定の方法にて納品確認を完了（以下、「納品を承認」といいます。）することで、クリエイターはサービスを提供する義務を果たしたとみなされます。
                    クリエイターによる納品後、クライアントが運営会社所定の期間以内に納品を承認せず、かつ、次項に定める修正依頼を行わない場合、納品を承認したものとみなします。
                    無保証での提供である場合には、クリエイターが納品した時点で、クリエイターはサービスを提供する義務を果たしたとみなされます。
                  </li>
                  <li>
                    クライアントは納品確認時に、クリエイターが納品したサービスが有効資料との間に不適合があることを発見した場合には、
                    不適合の内容を具体的に示した追加資料を提供し、運営会社が所定の方法でクリエイターに修正依頼を出すことができます。
                    修正依頼を受けたクリエイターは契約時資料と撤回していない追加資料に適合するようサービスを修正し、再度サービスを納品するものとします。
                    クライアントは運営会社が別途定める修正回数まで修正依頼を出すことができますが、利用者間契約にて修正回数を定めている場合、利用者間契約にて定めた修正回数まで修正依頼を出すことができます。
                  </li>
                  <li>
                    クライアントおよびクリエイターは利用者間契約締結後の契約解除について、双方の同意のもと、運営会社所定の方法によりこれを行うものとします。
                    クライアントおよびクリエイターの片方から契約解除の通知があったのち、運営会社が指定する期間以内にもう片方より契約解除に関する同意、不同意の意思表示がない場合、同意があったものとして契約を解除し、両者はこれに意義を唱えないものとします。
                    ただし、無保証での提供である場合、クライアント側から契約解除を行うことはできず、クリエイターからの契約解除の通知をもって契約解除とします。
                    また、利用者間契約において定められた納期までにクリエイターから納品がなかった場合、クライアントの契約解除の通知をもって契約解除とします。
                    クライアントは、契約解除に先立ち、クリエイターに対してサービスを提供する義務の履行を求めることができるものとします。
                  </li>
                </ol>
              </li>
              <li>
                利用者間契約締結前後において利用者間でやり取りされるコンテンツ（文書や資料、納品されたサービス、その他一切の内容を含みます。これを「メッセージ」といいます。）の送信者、受信者、および運営会社をメッセージの当時者とし、他の第三者が閲覧することはできないものとします。
                運営会社は利用者による本規約に違反する行為や不正の有無を確認すること、トラブルを未然に防止することを目的に、本サービスを提供するに当たって必要な限りにおいて、かつ、法令に違反しない限りにおいて、メッセージの内容を確認できるものとし、確認した内容に基づいて本規約に基づく措置をとるものとします。
              </li>
              <li>
                クリエイターは運営会社に対し、本規約に定める条件に従い、クライアントから支払われる報酬を代理受領する権限を付与するものとします。
                クライアントは、利用者間契約締結時に運営会社がクリエイターに代わり報酬を支払い、この時点でクライアントのクリエイターに対する報酬の支払いは完了したものとします。
                代理受領した報酬は、クリエイターがサービスの提供する義務を果たした時点で、運営会社が指定する方法で支払うものとします。
              </li>
              <li>
                クリエイターは、運営会社に対し、報酬金額に手数料率を乗じることで算出される金額（以下、「手数料」といいます。）を支払う義務を負うものとします。
                運営会社は、前項に定める報酬のクリエイターに対する引き渡しの際に、報酬から手数料を控除した金額を、運営会社が指定する方法により支払うものとします。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第8条（禁止行為）
          </Typography>
          <Typography variant="body2">
            利用者は本サービスの利用にあたって、次の行為をおこなってはなりません。
            <ol>
              <li>
                利用者、および第三者の知的財産権、その他の権利又は利益を侵害又は侵害を助長する行為
              </li>
              <li>第三者の著作物を二次利用する場合において、権利者の利益を不当に害する行為</li>
              <li>公序良俗に反する行為</li>
              <li>
                法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為
              </li>
              <li>
                過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、
                自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を送信する行為
              </li>
              <li>運営会社、利用者または第三者になりすます行為</li>
              <li>運営会社、利用者または第三者に対する非難、嫌がらせや誹謗中傷を目的とする行為</li>
              <li>反社会的勢力に対する利益供与、その他の協力行為</li>
              <li>
                通常の取引を装って架空の報酬の授受を行う等、本サービス利用以外の目的で送金手段として利用する行為、またはマネーロンダリング等に関与する行為
              </li>
              <li>
                政治的又は宗教的思想を含む情報を提供する行為及び政治的又は宗教的な勧誘を行う行為
              </li>
              <li>運営会社が認めていない営業、宣伝、広告、勧誘、その他営利を目的とする行為</li>
              <li>
                他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為
              </li>
              <li>
                不正アクセス、改ざん及びコンピューター・ウィルスを含む電子メールなど有害なコンピューター・プログラム等により、本サービス、運営会社、または利用者を攻撃する行為
              </li>
              <li>
                コンピューター・ウィルスを含む電子メールなど有害なコンピューター・プログラム等を本サービス、運営会社、または利用者に送信する行為
              </li>
              <li>本サービスを介さず、APIサーバーなどにアクセスする行為</li>
              <li>BOT、スクレイピング、その他の技術的手段を利用してサービスを不正に操作する行為</li>
              <li>本サービスの不具合を意図的に利用する行為</li>
              <li>クリエイター又はクライアントの判断に錯誤を与えるおそれのある行為</li>
              <li>納品を承認する意図がないにもかかわらず利用者間契約を結ぶ行為</li>
              <li>
                自動的に応答する等の機能を有する装置、ソフトウェア、アルゴリズム等を利用して本サービスを利用する行為
              </li>
              <li>
                不当な要求を執拗に繰り返すなどして、出品者又は購入者による応答や対応を強要する行為
              </li>
              <li>
                一般的な社会常識に照らして重大な瑕疵のないサービス等について、代金の支払い忌避を目的に、繰り返し、サービス提供契約の目的物又は役務の受け取りを拒否する行為
              </li>
              <li>
                同様の質問を必要以上に繰り返す等、運営会社や利用者に対し不当な問い合わせまたは要求をする行為
              </li>
              <li>
                本サービスの運営または他の利用者による本サービスの利用を妨害し、これらに支障を与える行為運営会社が委託する事業者の利用規約に違反する行為
              </li>
              <li>運営会社が委託する事業者の利用規約に違反する行為</li>
              <li>その他、運営会社が不適当と判断した行為</li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第9条 利用者の責任
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                利用者は、送信するメッセージが法令および本規約に違反していないことを保証するものとします。
                特にクリエイターは、自らの事業に関連する法規を遵守することを表明保証するものとします。
              </li>
              <li>
                利用者は、利用者の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為およびその結果について一切の責任を負うものとします。特にクリエイターは、
                他の利用者からの苦情に対して、自己の責任と費用において対応するものとし、また、それに対応する体制を整えていることを表明保証するものとします。
              </li>
              <li>
                利用者は、本サービスを利用したことに起因して、運営会社が直接的もしくは間接的に何らかの損害を被った場合、
                運営会社の請求にしたがって直ちにこれを補償しなければなりません。
              </li>
              <li>
                クリエイターは、作品に対しての一切の責任を負うものとし、販売責任者として、
                法令および経済産業省制定「電子商取引及び情報財取引等に関する準則」をはじめとする各ガイドラインを遵守することを保証します。
              </li>
              <li>
                利用者は、運営会社が、経済産業省制定「電子商取引及び情報財取引等に関する準則」で定める
                「ユーザー間取引プラットフォームのサービス運営事業者」であることを確認し、作品に対して一切の責任を負わないことに同意します。
              </li>
              <li>
                利用者は、クライアントが提供するサービスに関する紛争をクリエイターとクライアントの二者間のみで解決することに同意します。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第10条 反社会的勢力の排除
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                利用者は、過去、現在および将来にわたり、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者をいいます。）に該当しないことを表明保証し、
                および暴力的行為、詐術・脅迫行為、業務妨害行為等、法令に抵触する行為またはそのおそれのある行為を行わないことを約束します。
              </li>
              <li>
                利用者が前項の規定に違反した場合には、運営会社は事前の告知なく、当該利用者による本サービスの利用を停止し、または登録を削除する等の措置を講じることができるものとします。
                これにより当該利用者に何らの不利益または損害が生じたとしても、運営会社は一切の責任を負わないものとします。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第11条 運営会社の免責
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>
                運営会社は、本サービスに事実上または法律上の瑕疵がないことを明示的にも黙示的にも保証しません。運営会社は、利用者に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
                ただし、運営会社は、本サービスおよび本サービスの提供において法令違反がないよう努めるものとします。
              </li>
              <li>
                運営会社は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。
                ただし、本サービスに関する運営会社と利用者との間の契約が消費者契約法に定める消費者契約となる場合、本項および次項は適用されません。
              </li>
              <li>
                運営会社は、運営会社の過失による債務不履行または不法行為により利用者に生じた損害のうち、
                天災や事故など特別な事情から生じた損害について一切の責任を負いません。
              </li>
            </ol>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第12条 プライバシー
          </Typography>
          <Typography variant="body2">
            運営会社は、利用者の個人情報をプライバシーポリシーに基づき適切に取り扱うものとします。
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第13条 準拠法および管轄裁判所
          </Typography>
          <Typography variant="body2">
            本規約の準拠法は日本法とし、運営会社と利用者の間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第14条 改定
          </Typography>
          <Typography variant="body2">
            運営会社は、本規約について、事前に利用者へ適切な周知を行うことで、利用者の同意を得ず、改定できるものとします。
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'right', width: '100%', marginTop: '2rem' }}>
            <Typography variant="body2" sx={{}}>
              2024年4月1日 制定
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TOS;
