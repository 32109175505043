import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../../Template/Title';
export function Features() {
  return (
    <React.Fragment>
      <TitleDynamic title="機能" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '3rem', fontWeight: '600' }}
          >
            aaaaa
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant="h6" textAlign={'center'}>
            入力したURLが当サイトのページと一致しません。
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem', marginBottom: '8rem' }}></Grid>
      </Grid>
    </React.Fragment>
  );
}
