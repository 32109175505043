import React from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './Pages/App';
import Asct from './Pages/Leagal/Asct';
import AboutUs from './Pages/Leagal/AboutUs';
import Privacy from './Pages/Leagal/Privacy';
import Sender from './Pages/Leagal/Sender';
import TOS from './Pages/Leagal/termsOfService';
import YoutubeRedirect from './Pages/General/Youtube';
import NOTFOUND from './Pages/General/Notfound';
import RedirectToTop from './Pages/General/RedirectToTop';
import { Features } from './Pages/Description/Features';
const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/redirect/youtube/:id" element={<YoutubeRedirect />}></Route>
      <Route path="/redirect/*" element={<RedirectToTop />}></Route>
      <Route path="/asct" element={<Asct />}></Route>
      <Route path="/aboutus" element={<AboutUs />}></Route>
      <Route path="/privacy" element={<Privacy />}></Route>
      <Route path="/sender" element={<Sender />}></Route>
      <Route path="/termofservice" element={<TOS />}></Route>
      <Route path="/features" element={<Features />}></Route>
      <Route path="*" element={<NOTFOUND />}></Route>
    </Routes>
  );
};
export default Router;
