import React from 'react';
import { Grid, Stack, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { Titlelogo } from './TopBar';
export function BottomBar() {
  return (
    <div id="BottomBar">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Titlelogo />
                BuildFanBase!で動画制作をしている人たちに加わろう。
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container sx={{ marginTop: '3rem' }}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      <Typography variant={'body1'}>
                        <Link to="/aboutus">会社概要</Link>
                      </Typography>
                      <Typography variant={'body1'}>
                        <Link to="/termofservice">利用規約</Link>
                      </Typography>
                      <Typography variant={'body1'}>
                        <Link to="/asct">特定商取引法に基づく表記</Link>
                      </Typography>
                      <Typography variant={'body1'}>
                        <Link to="/privacy">プライバシーポリシー</Link>
                      </Typography>
                      <Typography variant={'body1'}>
                        <Link to="/sender">利用者情報の外部送信について</Link>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
