import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../Template/Title';
function App() {
  return (
    <React.Fragment>
      <TitleDynamic title="" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '4rem', fontWeight: '600' }}
          >
            愛される動画を作ろう
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant="h6" textAlign={'center'}>
            動画制作に関するアドバイスを、BuildFanBase! で手に入れよう。
            <br />
            BuildFanBase!
            は動画制作のプロや視聴者からアドバイスを受けられるコミュニケーションツールです。
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem', marginBottom: '8rem' }}></Grid>
      </Grid>
    </React.Fragment>
  );
}

export default App;
