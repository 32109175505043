import React, { useEffect } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../../Template/Title';
export function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <TitleDynamic title="プライバシーポリシー" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '4rem', fontWeight: '600' }}
          >
            プライバシーポリシー
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant="body2">
            本プライバシーポリシーは、BuildFanbase!（以下、運営会社といいます）が提供する「BuildFanBase!」（以下、「本サービス」といいます）において、運営会社と本サービスを利用する利用者（以下、「利用者」といいます。）のプライバシーを尊重し、利用者の個人情報を適切に管理、運用することを目的として、本サービスにおける個人情報の取り扱いと責任の所在を明らかにするものです。
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第1条 定義
          </Typography>
          <Typography variant="body2">
            運営会社では、次の情報を個人情報と定めます。
            <ul>
              <li>
                個人情報の保護に関する法律に規定される、生存する個人に関する情報(氏名、住所、電話番号、その他の特定の個人を識別することができる情報)
              </li>
              <li>連絡先情報（メールアドレス、電話番号）</li>
              <li>アクティビティ（クリックしたリンク、ボタン、マウスの位置、参照元のURLなど）</li>
              <li>
                ウェブサイトのコンテンツ（閲覧したページのテキスト、画像、音声、ビデオ、URLなど）
              </li>
            </ul>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第2条 利用目的
          </Typography>
          <Typography variant="body2">
            運営会社は、個人情報を次の目的で利用します。
            <ul>
              <li>本サービスの役務の提供</li>
              <li>本サービスに関する案内、問い合わせへの対応</li>
              <li>規約とポリシーに違反する行為への対応</li>
              <li>規約の変更や重要な情報の利用者への通知</li>
              <li>本サービスを含む運営会社に関連する事業の宣伝</li>
              <li>本サービスの改善、新サービスの開発</li>
              <li>個別の識別情報を含まない統計情報の作成</li>
              <li>その他、上記利用目的に付随する目的</li>
            </ul>
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第3条 取得方法
          </Typography>
          <Typography variant="body2">
            運営会社は、お客様による運営会社が提供するサイトおよびサービスの使用を通じて、お客様に関する情報を直接収集します。
            運営会社が提供するサイトへのすべての訪問者は、運営会社が提供するサービスを利用せずに閲覧することができますが、
            運営会社が提供するサービスを最大限に活用するには、以下を含む特定の情報を提供する必要があります。
            <ol>
              <li>お客様より直接提供される情報</li>
              <ul>
                <li>名前</li>
                <li>ログイン情報</li>
                <li>メールアドレスや電話番号などの連絡先情報</li>
                <li>Youtubeのチャンネル詳細</li>
              </ul>
              <li>運営会社が自動的に収集する情報</li>
              運営会社は、Cookieまたはその他の追跡技術を通じて、お客様のサイトおよびサービスの使用に関する情報を自動的に収集します。
              <ul>
                <li>デバイスID</li>
                <li>ブラウザの種類とオペレーティングシステム</li>
                <li>あなたが閲覧するウェブページ</li>
                <li>クリックしたリンク、ボタン、またはその他の要素</li>
                <li>閲覧したページのテキスト、画像、音声、ビデオ</li>
                <li>IPアドレス</li>
                <li>
                  お客様が運営会社が提供するサイトにアクセスしたり、運営会社のサービスを利用した時間の長さ
                </li>
                <li>参照元のURL、または運営会社が提供するサイトに誘導したWebページ</li>
              </ul>
              <li>第三者サービスによって提供される情報</li>
              運営会社は、YouTubeの利用規約で許可されているとおり、運営会社のツールで使用するために、お客様のYouTubeチャンネルに関するデータを収集する場合があります。
              運営会社が提供するサイトおよびサービス内で
              YouTubeコンテンツを使用する場合、YouTubeの利用規約および Google のプライバシー
              ポリシーに同意したことになります。
              さらに、お客様は運営会社アカウントにサインアップするときに、Googleが提供する方法を通じて運営会社のサービスに対する認証を行うことでGoogleが保有する個人情報およびその他のデータに継続的にアクセスすることを運営会社に許可します。
              YouTube APIから受け取った情報の使用は、要件を含むChrome ウェブストアのユーザー
              データポリシーに準拠します。ユーザーは、Googleセキュリティ設定で説明されている手順に従って、YouTube
              API YouTube APIサービス経由で収集されたデータへのアクセスを取り消すことができます。
            </ol>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第4条 第三者への提供
          </Typography>
          <Typography variant="body2">
            運営会社は、利用者の同意を得ず、利用者の個人情報を第三者に提供しません。ただし、次の場合はこの限りではありません。
            <ul>
              <li>
                運営会社が、利用者との間で対価の支払い、または受け取りを行う目的で、決済代行事業者に委託する場合
              </li>
              <li>
                運営会社が、利用者にメールの配信を行う目的で、配信サービスを有する事業者に委託する場合
              </li>
              <li>運営会社が、法務を遂行する目的で、弁護士に委託する場合</li>
              <li>運営会社が、税務を遂行する目的で、税理士に委託する場合</li>
              <li>
                その他、運営会社が、本サービスの業務を遂行する目的で、業務の一部を事業者に委託する場合
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第5条 取り扱いの制限
          </Typography>
          <Typography variant="body2">
            運営会社は、利用者の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。ただし、次の場合はこの限りではありません。
            <ul>
              <li>法令に基づく場合</li>
              <li>
                人の生命、身体または財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合
              </li>
              <li>
                国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第6条 開示
          </Typography>
          <Typography variant="body2">
            会社は、利用者から利用者本人の個人情報の開示を求められた場合は、その開示に応じます。ただし、次の場合にはその全部または一部を開示しない場合があります。
            <ul>
              <li>法令に違反する恐れがある場合</li>
              <li>利用者本人または第三者の生命・身体・財産その他の権利を害するおそれがある場合</li>
              <li>開示に応じることが運営会社の業務遂行に著しい障害を生じさせる場合</li>
            </ul>
            開示の方法は、開示の請求を行った者の同意を得た場合には、電子メールの方法によります。ただし、開示の請求を行った者から開示の方法について特に指定がなく、かつ、電子メールによる開示の方法について異議を述べなかった場合には、当該方法について同意があったものとします。
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第7条 正確性の保証
          </Typography>
          <Typography variant="body2">
            利用者は、個人情報の内容が常に正確であることを運営会社に対して保証し、不正確な個人情報によって発生した損害について、利用者が一切の責任を負うものとします。
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第8条 修正
          </Typography>
          <Typography variant="body2">
            運営会社は、利用者から個人情報の訂正、追加等を求められた場合には、当該利用者本人であること、ならびに訂正内容の正確性を確認し、適切な対応をします。
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第9条 Cookie
          </Typography>
          <Typography variant="body2">
            運営会社は、利用者の利便性の向上と利用状況の把握を目的として本サービスでCookieを使用する場合があります。
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第10条 免責
          </Typography>
          <Typography variant="body2">
            運営会社は、第三者による個人情報の取り扱いに関し、次に掲げる場合には一切の責任を負いません。
            <ul>
              <li>
                利用者が、本サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合
              </li>
              <li>
                第三者が、外部の手段を用いて取得した利用者の個人情報を本サービスの機能を用いて明らかにした場合
              </li>
            </ul>
          </Typography>

          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            第11条 改定
          </Typography>
          <Typography variant="body2">
            運営会社は、本プライバシーポリシーについて、事前に利用者へ適切な周知を行うことで、利用者の同意を得ず、改定できるものとします。
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'right', width: '100%', marginTop: '2rem' }}>
            <Typography variant="body2" sx={{}}>
              2024年4月1日 制定
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ width: '100%' }} />

          <Typography
            variant="h6"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            補足事項
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>運営会社では、クレジットカード情報を取得、保管せず、委託会社にて管理します。</li>
              <li>
                取引履歴(内容、金額、日時)は、平均価格などの統計情報に加工され、公開される場合があります。
              </li>
              <li>
                運営会社が使用するCookieのうち、利用状況の把握について、Google Inc.が提供するGoogle
                Analyticsが提供するコンバージョントラッキングを用いて、個人を特定しない情報を収集します。収集される情報の取り扱いと責任については各社のプライバシーポリシー(Google
                Inc.)が適用されます。
              </li>
              <li>
                個人情報に関する問い合わせの場合は、特定商取引法に基づく表記ページの連絡先に問い合わせください。
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Privacy;
