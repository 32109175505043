import React, { useEffect } from 'react';
import { LinearProgress, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { TitleDynamic } from '../../Template/Title';

function YoutubeRedirect() {
  const params = useParams();
  const videoId = params.id;
  const url = videoId
    ? 'https://www.youtube.com/watch?v=' +
      videoId.split('&')[0].replace('?', '').replace('=', '').replace('<', '').replace('>', '') +
      '&checker=review'
    : '/';
  window.location.href = url;
  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById('targetLink');
      elem?.click();
    }, 0);
  }, []);

  return (
    <React.Fragment>
      <TitleDynamic title="リダイレクト中..." />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '3rem', fontWeight: '600' }}
          >
            リダイレクト中...
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress color="success" />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant="h6" textAlign={'center'}>
            動画へリダイレクト中です...
            <br />
            <Link id="targetLink" to={url}>
              リダイレクトされない場合はこのリンクをクリックして下さい。
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem', marginBottom: '8rem' }}></Grid>
      </Grid>
    </React.Fragment>
  );
}

export default YoutubeRedirect;
