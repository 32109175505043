import React from 'react';
import { Helmet } from 'react-helmet';

interface TitleProps {
  title: string;
}
export function TitleDynamic(props: TitleProps) {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {props.title}
          {props.title === '' ? '' : ' - '}BuildFanBase!
        </title>
      </Helmet>
    </React.Fragment>
  );
}
