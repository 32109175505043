import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../../Template/Title';
function createData(title: string, value: string) {
  return { title, value };
}

const rows = [
  createData('屋号', 'BuildFanBase!'),
  createData('所在地', '千葉県流山市西初石'),
  createData('連絡先', 'legends.of.vtuber@gmail.com'),
  createData('創業', '2024年4月1日'),
  createData('代表', '山口達也'),
  createData('事業内容', '動画制作に関するマーケットプレイス運営, 及び情報分析とコンサルティング'),
];

export function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <TitleDynamic title="会社概要" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '4rem', fontWeight: '600' }}
          >
            会社概要
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: '160px' }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {row.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AboutUs;
