import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../../Template/Title';
export function Sender() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <TitleDynamic title="利用者情報の外部送信について" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '4rem', fontWeight: '600' }}
          >
            利用者情報の外部送信について
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant="body2">
            当社では、アクセス解析によるウェブサイトのサービス改善のため、
            クッキー等の端末識別子を利用してサイト利用者に関する情報（利用者情報）を収集し、外部事業者に送信しています。
            外部事業者の名称(サービス名)、外部事業者に送信される利用者情報の内容、
            送信される情報の利用目的については、以下より詳細をご確認ください。
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: '2rem', marginBottom: '0.5rem', fontWeight: '800' }}
          >
            Google LLC (サービス名 : Google Analytics)
          </Typography>
          <Typography variant="body2">
            送信される利用者情報は以下のとおりです
            <ul>
              <li>クライアントID</li>
              <li>IPアドレス</li>
              <li>閲覧ページURL</li>
              <li>リファラー</li>
              <li>デバイスタイプ</li>
              <li>オペレーティングシステム</li>
              <li>ブラウザタイプ</li>
              <li>利用言語</li>
            </ul>
            これらの情報は、サイト利用者に関する情報を分析し、サービス改善のために利用することを目的に送信されます。
          </Typography>
          <Typography variant="body2">
            Google LLC (サービス名 : Google Analytics)によるプライバシーポリシーは
            <a href="https://policies.google.com/privacy">こちらのページ</a>をご確認下さい。
            <br />
            オプトアウトについては
            <a href="https://tools.google.com/dlpage/gaoptout">こちらのページ</a>をご確認下さい。
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Sender;
