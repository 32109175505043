import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from './icon__32.png';

interface page {
  key: string;
  buttonLabel: string;
  Link: string;
}

export function TopBar() {
  const pages: page[] = [
    { key: 'product', buttonLabel: '機能', Link: '/features' },
    { key: 'pricing', buttonLabel: '価格', Link: '/pricing' },
  ];
  return (
    <AppBar position="static" color="transparent" elevation={1} id="TopBar">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex' }, marginRight: 'auto' }}>
            <Titlelogo />
          </Box>

          <Stack direction={'row'} sx={{ flexGrow: 0, float: 'right' }}>
            {pages.map((page) => (
              <Button
                key={page.key}
                sx={{
                  my: 2,
                  color: 'black',
                  fontSize: '1rem',
                  display: 'block',
                  textAlign: 'center',
                }}
                component={Link}
                to={page.Link}
              >
                {page.buttonLabel}
              </Button>
            ))}
            <IconButton sx={{ p: 0, my: '16px', marginLeft: '6px' }} component={Link} to={'/login'}>
              <Avatar alt="Account" />
            </IconButton>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export function Titlelogo() {
  return (
    <Link to="/" className="NoDecoration">
      <Toolbar disableGutters sx={{ padding: 0 }}>
        <img src={Logo} alt="BuidFanBase Logo" width={32} height={32} />
        <Typography
          variant="h6"
          noWrap
          sx={{
            ml: 1,
            mr: 2,
            fontWeight: 700,
            textDecoration: 'none',
          }}
          className="mainColor"
        >
          BuildFanBase!
        </Typography>
      </Toolbar>
    </Link>
  );
}
