import React from 'react';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import { TitleDynamic } from '../../Template/Title';
function createData(title: string, value: string) {
  return { title, value };
}

const rows = [
  createData('販売業社の名称', '山口達也'),
  createData('所在地', '千葉県流山市西初石 ※請求があれば遅滞なく開示いたします。'),
  createData('電話番号', '※請求があれば遅滞なく開示いたします。'),
  createData('メールアドレス', 'legends.of.vtuber@gmail.com'),
  createData(
    '特定商取引法に基づく申立窓口',
    '特定商取引法に基づく申立については専用窓口（このページ内に記載）よりご連絡下さい。',
  ),
  createData('運営統括責任者', '山口達也'),
  createData(
    '追加手数料等の追加料金',
    'クリエイターが提供する商品の取引機会提供について、クリエイターが設定する販売価格の10%を徴収します。',
  ),
  createData(
    '役務の対価以外の必要料金',
    '当社サービスを利用するためにかかる費用（機器、インターネット接続料金、通信料金を含む）はお客様のご負担となります。',
  ),
  createData(
    '返金ポリシー＜お客様都合の返品・交換の場合＞',
    '役務の特性上、返金についてはお受けできません。',
  ),
  createData(
    '返金ポリシー＜商品・役務に不備がある場合＞',
    'お支払い履歴より返金の手続きを行うか、特定商取引法に基づく申立窓口にご連絡下さい。',
  ),
  createData(
    '役務の内容',
    'クリエイターが提供する商品の取引機会提供および商品の提供, 弊社が提供する役務の提供',
  ),
  createData('役務の引き渡し時期', '商品、役務によって異なるため、購入画面に表示いたします。'),
  createData('役務の対価の支払い方法', 'Google Pay, クレジットカード決済'),
  createData(
    '決済期間',
    '「クリエイターが提供する商品の取引機会提供」に対する対価の引き渡し時期は、クライアントとの取引成立時とします。 「クリエイターが提供する商品の提供」、および「弊社が提供する役務の提供」に対する対価の引き渡し時期は注文確定時とします。',
  ),
  createData('販売価格', '商品、役務によって異なるため購入画面に表示いたします。'),
];

function Asct() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <TitleDynamic title="特定商取引法に基づく表記" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            textAlign={'center'}
            sx={{ fontSize: '4rem', fontWeight: '600' }}
          >
            特定商取引法に基づく表記
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: '160px' }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {row.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12} sx={{ marginTop: '1rem' }}>
            <Typography variant="h6">補足事項</Typography>
            <Typography variant="body1">
              <ul>
                <li>
                  Build Fan
                  Base!では、取引仲介システムを通して、「クリエイターが提供する商品」の取引機会の提供を行っています。
                </li>
                <li>
                  「クリエイターが提供する商品」については、各販売者が販売責任者であり、商品に対する販売責任が及びます。
                </li>
              </ul>
            </Typography>
            <Typography variant="h6">特定商取引法に基づく申立専用窓口</Typography>
            <Typography variant="body1">
              <ul>
                <li>
                  この窓口では、仕様確認や質問、ご意見などの法令に基づかない問い合わせには返信いたしません。
                </li>
                <li>
                  また、返金手続きには最大90日程度かかることが見込まれます。90日経過していない場合、問い合わせには返信いたしかねます。
                </li>
                <li>
                  法令に基づく問い合わせの場合は
                  <a href="https://forms.gle/UuRDkRgF7jtJHMqa9">こちら</a>からお問い合わせ下さい
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Asct;
